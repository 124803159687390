<template>
  <div class="signup-container mt-md-10">
    <div v-if="!sended" class="text-left py-5">
      <div class="px-5">
        <h1 class="display-1 font-weight-bold accent--text">
          Registra tu perfil en XUMED
        </h1>
        <div class="mt-1">
          Si tienes una cuenta ya creada,
          <span
            @click="goTo('/login')"
            style="text-decoration: underline; cursor: pointer"
            >inicia sesión</span
          >.
        </div>
      </div>
      <v-row no-gutters class="mt-8">
        <v-col md="6" cols="12" class="px-5">
          <v-text-field
            v-model="name"
            type="text"
            label="Nombre *"
            filled
          ></v-text-field>
        </v-col>
        <v-col md="6" cols="12" class="px-5">
          <v-text-field
            v-model="phone"
            type="text"
            label="Teléfono de contacto *"
            append-icon="mdi-phone"
            filled
          ></v-text-field>
        </v-col>
        <v-col md="6" cols="12" class="px-5">
          <v-text-field
            v-model="email"
            type="text"
            label="Correo electrónico *"
            append-icon="mdi-email"
            filled
          ></v-text-field>
        </v-col>
        <v-col md="6" cols="12" class="px-5">
          <v-combobox
            v-model="country"
            :items="countries"
            label="País *"
            filled
          ></v-combobox>
        </v-col>
        <v-col md="8" cols="12" class="px-5 d-flex align-center">
          <div style="cursor: pointer; opacity: 0.6; font-size: 14px">
            Al registrarte, aceptas nuestras
            <span
              style="text-decoration: underline"
              @click="openNewTab('/legal')"
            >
              políticas de privacidad y los términos de uso</span
            >
            de la plataforma.
          </div>
        </v-col>
      </v-row>
      <div class="text-left px-5 pt-6">
        <v-btn
          large
          depressed
          @click="save"
          color="primary"
          class="btn-send"
          :disabled="invalidForm"
          :loading="loading"
        >
          <span>Enviar</span>
        </v-btn>
      </div>
    </div>
    <div v-else class="text-center py-5 mt-16">
      <p class="display-1 font-weight-bold accent--text">
        ¡Gracias por registrarte!
      </p>
      <div>
        En breve, un miembro de nuestro equipo se comunicará contigo para
        indicarte las próximas instrucciones del proceso.
      </div>
      <v-btn to="/" color="primary" class="mt-10" depressed
        >Volver al inicio</v-btn
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SingUp",
  data() {
    return {
      name: "",
      country: "República Dominicana",
      phone: "",
      email: "",
      loading: false,
      sended: false,
    };
  },
  computed: {
    ...mapGetters(["countries"]),
    invalidForm() {
      return !this.name || !this.country || !this.phone || !this.email;
    },
  },
  methods: {
    ...mapMutations(["setAlert"]),
    async save() {
      try {
        if (this.loading) {
          return false;
        }
        this.loading = true;
        await axios.post("/api/contact", {
          name: this.name,
          phone: this.phone,
          email: this.email,
          country: this.country,
        });

        this.sended = true;
      } catch (error) {
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    goTo(route) {
      this.$router.push(route);
    },
    openNewTab(path) {
      // Specify the URL for the new tab
      const newTabUrl = path; // Change this to the desired path

      // Create an anchor element
      const anchor = document.createElement("a");

      // Set the href attribute to the desired URL
      anchor.href = newTabUrl;

      // Set the target attribute to _blank to open in a new tab
      anchor.target = "_blank";

      // Trigger a click event on the anchor element
      anchor.click();
    },
  },
};
</script>

<style scoped>
.btn-send {
  width: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.signup-container {
  padding-top: 100px;
  padding: 0px 100px;
  @media (max-width: 768px) {
    padding-top: 50px;
    padding: 0px;
  }
}
</style>
